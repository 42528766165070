@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 900;
  src: url('fonts/eot/IRANSansWeb_Black.eot');
  src: url('fonts/eot/IRANSansWeb_Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb_Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb_Black.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb_Black.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('fonts/eot/IRANSansWeb_Bold.eot');
  src: url('fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('fonts/eot/IRANSansWeb_Medium.eot');
  src: url('fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('fonts/eot/IRANSansWeb_Light.eot');
  src: url('fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('fonts/eot/IRANSansWeb_UltraLight.eot');
  src: url('fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('fonts/eot/IRANSansWeb.eot');
  src: url('fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
  url('fonts/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
  url('fonts/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('fonts/ttf/IRANSansWeb.ttf') format('truetype');
}

body {
  background: #27262B;
  direction: rtl;
  color: white;
  overflow-x: hidden;
}

.app-container {
  margin-right: 245px;
  @media(max-width: 575px) {
    margin-right: 0;
  }
}

.header {
  height: 80px;

  .form-control {
    height: 25px;
    border: 0;
  }
}

.blur-effect {
  background: rgba(39, 38, 43, 0.56);
  position: relative;
  min-height: 200px;
  backdrop-filter: blur(24px);
  box-shadow: inset 0 1px 0 0 #ffffff1c;
}

.data-switch {
  background: #1E1D21;
  border-radius: 19px;
  font-size: 12px;
  .data-switch__item {
    border-radius: 19px;
    cursor: pointer;
    &.active {
      background: #717DFE;
    }
  }
}

.dropdown .btn {
  border-radius: 19px;
}

.dropdown-menu {
  backdrop-filter: blur(20px);
}

.dropdown-toggle::after {
  border: none;
  background: url(images/caret-down.svg) no-repeat center;
  width: 11px;
  height: 7px;
  margin-left: 0;
}

.btn-transparent:active:focus {
  box-shadow: 0 0 0 0.25rem lighten(#717DFE, 10%) !important;
}

.show > .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #717DFE !important;
}

.dropdown-menu {
  font-size: 15px;
  text-align: right;
}

.locked {
  overflow: hidden;
}

.rc-scrollbars-view {
  margin-right: 0 !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rc-scrollbars-track {
  left: 2px !important;
  right: unset !important;
  top: 4px !important;
  bottom: 4px !important;
}

.modal {
  h4 {
    font-size: 18px;
  }

  .modal-dialog {
    max-width: 450px;
  }

  .modal-footer {
    background: #14141D;
    font-size: 14px;
  }
}
.modal-backdrop {
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.1);
  &.show {
    opacity: 1;
  }
}

.btn-primary {
  color: white;
  &:disabled {
    background: #737ef7;
    border-color: #737ef7;
  }
  &:hover {
    color: white;
  }
}

.ant-notification-notice {
  background: #1C1C25;
  color: white;
}

@-moz-document url-prefix() {
  .blur-effect {
    background: #27262b;
  }
}

@media(max-width: 500px) {
  .glare-wrapper {
    display: none !important;
  }
}