.streamer-snippet {
  max-height: 75px;
  overflow: hidden;

  &.ltr {
    direction: ltr;
    text-align: left;
  }
  .streamer-snippet__title {
    height: 20px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.9)
  }
  .streamer-snippet__avatar {
    img {
      width: 34px;
      height: 34px;
    }
  }
  .streamer-snippet__username {
    font-size: 14px;
    color: #e4e5e7;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 130px;
  }
  .streamer-snippet__category {
    font-size: 12px;
    color: #91949a;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 118px;
  }
  .streamer-snippet__view-indicator {
    background: #717DFE;
    width: 8px;
    height: 8px;
    font-size: 13px;
    position: relative;
    top: -1px;
  }
  .streamer-snippet__view-count {
    color: #C4C4C7;
    font-size: 13px;
  }
}

.tiny {
  max-width: 77px !important;
  direction: ltr;
}