//  *** RESPONSIVE BREAK POINTS ***
$grid-breakpoints: (
        xxs: 0px,
        xs: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
);

//  *** CONTAINER WIDTHS ***
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1340px,
);

$px-spacer: 10px;

$px-spacers: map-merge(
                (
                        0: 0,
                        half: $px-spacer / 2,
                        10: $px-spacer * 1,
                        15: $px-spacer * 1.5,
                        20: $px-spacer * 2,
                        25: $px-spacer * 2.5,
                        30: $px-spacer * 3,
                        35: $px-spacer * 3.5,
                        40: $px-spacer * 4,
                        45: $px-spacer * 4.5,
                        50: $px-spacer * 5,
                        55: $px-spacer * 5.5,
                        60: $px-spacer * 6,
                        65: $px-spacer * 6.5,
                        70: $px-spacer * 7,
                        80: $px-spacer * 8,
                        90: $px-spacer * 9,
                        100: $px-spacer * 10,
                        120: $px-spacer * 12,
                        130: $px-spacer * 13,
                        150: $px-spacer * 15,
                ),
                $spacers
);

$spacers: map-merge(
                (
                        0: 0,
                        1: (
                                $spacer * 0.25,
                        ),
                        2: (
                                $spacer * 0.5,
                        ),
                        3: $spacer,
                        4: (
                                $spacer * 1.5,
                        ),
                        5: (
                                $spacer * 3,
                        ),
                ),
                $px-spacers
);

$theme-colors: map-merge(
                $theme-colors,
                (
                        "dark-purple": #44446c,
                        "green": #42cb83,
                        "success": #42cb83,
                        "light": #f2f4f9,
                        "gray": #808393,
                        "purple": #6165ff,
                        "light-gray": #dfe2eb,
                        "white": #fff,
                        "blue": #6f8eff,
                        "secondary": #9A9DA4,
                        "twitch": #9146FF,
                        "transparent": transparent,
                        "primary": #6471ff
                )
);

$input-border-color: #e0e1e8;
$input-placeholder-color: #7d8ca4;
$input-border-width: 1px;
$input-bg: #fff;
$input-padding-y: 0.75rem;
$small-font-size: 85%;
$input-font-size: 15px;
$font-family-base: "IRANSans", sans-serif;
$alert-padding-y: 0.4rem;
$alert-padding-x: 1.4375rem;
$alert-border-radius: 10rem;
$link-color: #5378fc;
$link-hover-color: darken($link-color, 10%);
$btn-padding-y: 0.65rem;
$btn-padding-x: 1.3rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2.9rem;
$btn-padding-y-sm: 0.55rem;
$btn-padding-x-sm: 1.4rem;
$btn-font-size: 14px;
$border-radius: 0.7rem;
$nav-link-padding-y: 0.85rem;
$h2-font-size: 20px;
$dropdown-bg: #27262b4f;
$dropdown-color: white;
$dropdown-link-color: rgba(255, 255, 255, 0.8);
$dropdown-link-active-bg: #717DFE;
$dropdown-link-hover-bg: #717DFE;
$active-background: #717DFE;
$body-text-align: right;
$modal-content-bg: #1C1C25;
$modal-content-color: #fff;
$input-bg: #14141D;
$input-border-color: #14141D;
$input-color: #C9C9CD;
$input-focus-color: #FFF;
$input-focus-bg: #282836;
$input-focus-border-color: transparent;
$modal-footer-border-width: 0;
$modal-content-border-width: 0;
$input-focus-box-shadow: 0 0 0 0.15rem #717dfe;

.alert {
  font-size: 15px;
}

label {
  font-size: 15px;
}

.ant-notification {
  z-index: 9999999;
}