.banner {
  width: 100%;
  background-size: cover;
  background-position: center ;
  position: relative;
  margin-bottom: -120px;

  .banner__title {
    line-height: 28px;
  }

  .banner__container {
    height: 210px;
    color: white;
    position: relative;
    z-index: 10;
  }

  .banner__details, .banner__category {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;

    .banner__details__profile {
      border-color: rgba(255, 255, 255, 0.5) !important;
      border-radius: 8px !important;
    }
  }

  h2 {
    font-size: 22px;
  }

  &:after {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.79) 1%, rgba(0,0,0,0.49) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
  }
}

.live-indicator {
  position: relative;
  width: 46px;
  height: 46px;
  float: left;
  left: -5px;
}
.live-indicator__circle {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #717DFE;
  border-radius: 50%;
  left: 17px;
  top: 17px;
  z-index: 10;
}

.live-pulse-min {
  border: 3px solid #FFF;
  background: #fff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 16px;
  left: 16px;
  -webkit-animation: pulse 1.4s linear;
  -moz-animation: pulse 1.4s linear;
  animation: pulse 1.4s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
  opacity: 0;
}

.live-pulse-max {
  border: 3px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 8px;
  left: 8px;
  -webkit-animation: pulse2 1.4s linear;
  -moz-animation: pulse2 1.4s linear;
  animation: pulse2 1.4s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
  opacity: 0;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes pulse2 {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}