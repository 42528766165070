.header__notification {
  .dropdown-toggle {
    &:after {
      display: none;
    }
  }
  .dropdown-item {
    &:hover, &:focus {
      background: rgba(0, 0, 0, 0.1);
      color: white;
    }
  }
  .header__notification__item {
    .header__notification__item__message {
      font-size: 14px;
      line-height: 26px;
      margin-top: 10px;
    }
    .header__notification__item__indicator {
      i {
        background: #717DFE;
        width: 8px;
        height: 8px;
        font-size: 13px;
        position: relative;
        top: -1px;

      }
    }
  }
  .header__notification__badge {
    width: 17px;
    height: 17px;
    font-size: 13px;
    background: #717DFE;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 2px;
  }
}