.header {
  .header__search {
    color: #83848F;

    input {
      color: #83848F;
      outline: 0;
      border: 0;
      box-shadow: none;

      &::placeholder {
        color: #83848F
      }
    }
  }

  .header__avatar {
    .header__avatar__image {
      width: 30px;
    }
  }
}