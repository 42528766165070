.sidebar {
  width: 245px;
  height: 100vh;
  border-left: 1px solid rgba(255, 255, 255, 0.03);
  position: fixed;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .sidebar__category {
    font-size: 13px;
    line-height: 24px;
  }

  .sidebar__menu {
    font-size: 14px;

    a {
      color: white;

      &[aria-current="page"], &.active {
        background: #717DFE;
        border-radius: 5px;
      }
    }
  }

  .sidebar__show-more {
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
  }
}

.btn-twitch {
  img {
    position: absolute;
    right: 15px;
    margin-top: 3px
  }
}