$dark-skeleton: linear-gradient(
                90deg,
                darken(#515255, 15%) 20%,
                darken(#535457, 15%) 28%,
                darken(#57595C, 15%) 35%,
                darken(#535457, 15%) 42%,
                darken(#515255, 15%) 50%
);

$light-skeleton: linear-gradient(
                90deg,
                #E7E7E7 20%,
                #E7E7E7 28%,
                #EDEDED 35%,
                #E7E7E7 42%,

  // #E7E7E7 0%,
  // #E7E7E7 20%,
  // #EDEDED 28%,
  // #F0F0F0 35%,
  // #EDEDED 42%,
  // #E7E7E7 50%,
  // #E7E7E7 100%,
);

@mixin skeleton($background) {
  animation: skeleton-animation 3s infinite linear;
  background: $background;
  background-size: 300% 100%;
  will-change: background-position;
}

@keyframes skeleton-animation {
  0% {
    background-position: 100% 0%;
  }

  70% {
    background-position: -50% 0%;
  }

  100% {
    background-position: -50% 0%;
  }
}

.skeleton__card {
  background: #1E1D21;
  border-radius: 15px;
  overflow: hidden;
  min-height: 265px
}

.skeleton__thumbnail {
  height: 150px;
  width: 100%;
}

.skeleton__wave {
  @include skeleton($dark-skeleton);
  box-shadow: 150px 150px #707dfe0d inset;
}

.skeleton__title {
  width: 200px;
  height: 20px;
  border-radius: 15px;
}

.skeleton__avatar {
  width: 34px;
  height: 34px;
}