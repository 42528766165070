.live-feed__card {
  background: #1E1D21;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.03);
    transition: transform 0.5s;
  }

  .live-feed__thumbnail {
    img {
      height: 150px;
      object-fit: cover;
      width: 100%;
    }
  }
}